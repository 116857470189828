const infoBoxData = [
  {
    id: 1,
    title: "Més enllà de les enginyeries tradicionals",
    text: [
      "Què és l'enginyeria? No, no és només un senyor amb un peto taronja i un casc al cap. L'enginyeria és una disciplina que utilitza la ciència i les matemàtiques per resoldre problemes i crear coses útils en el món real, millorant així la vida de les persones. Per exemple, s’inventen noves tecnologies, es milloren sistemes de transport, es desenvolupen aplicacions mòbils i s’ajuda a construir ciutats més sostenibles. Hi ha una gran varietat de problemes i necessitats que requereixen solucions específiques, i per això l’enginyeria té diferents branques d’especialització, per tal de poder-se enfocar a fons en cada tipus de repte.",
      "Deixant de banda les enginyeries tradicionals, les Enginyeries de Computació i Tecnologies Digitals han sorgit més recentment amb l’avanç de la tecnologia informàtica i digital. Es caracteritzen per la seva relació estreta amb el desenvolupament de programes, sistemes digitals, gestió de dades, intel·ligència artificial, xarxes de comunicació i continguts multimèdia. Aquestes responen a les necessitats de l'era digital i poden tenir un impacte significatiu en el futur tecnològic. A més, hi ha una gran demanda de professionals en aquest camp, cosa que es tradueix en excel·lents oportunitats laborals."
    ],
    img: "vr.png",
    alt: "enginyeries_modernes",
    reversed: false
  },
  {
    id: 2,
    title: "Programar: L'Art de Donar Vida a les Idees",
    text: [
      "La programació és l'eina màgica que permet als ordinadors entendre i executar les nostres ordres, i així implementar i millorar tecnologies i sistemes. És com escriure una recepta detallada per a l'ordinador, indicant-li pas a pas què fer. Amb codi i un llenguatge de programació, les programadores creen aplicacions, jocs i llocs web que fan realitat les seves idees. En resum, programar és transformar conceptes en realitat digital.",
      "Aprendre a programar és un primer pas fonamental per a aquelles que volen treballar en aquestes enginyeries emergents o modernes, ja que proporciona les habilitats necessàries per dissenyar, implementar i millorar solucions tecnològiques. A més, aprendre programació és valuós fins i tot si no es vol estudiar enginyeria, i no és necessari ser un expert en matemàtiques o ciències. La programació millora la capacitat per resoldre problemes de manera lògica i estructurada, fomenta la creativitat en permetre't crear aplicacions i projectes tecnològics propis, i obre oportunitats laborals en molts sectors, des del màrqueting digital fins al disseny de productes. També permet automatitzar tasques repetitives i millorar l'eficiència en qualsevol àmbit professional. Les habilitats de programació són pràctiques i demanades, i poden beneficiar una àmplia gamma de carreres i projectes personals."
    ],
    img: "ideas.png",
    alt: "idees_programacio",
    reversed: true
  }
]

export default infoBoxData;