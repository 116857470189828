import React from 'react';

const CareerSelector = ({ careers, selectedCareer, onCareerChange }) => {
  return (
    <div className="career-selector">
      {careers.map((career) => (
        <button
          key={career.id}
          className={`career-button ${
            selectedCareer.id === career.id ? 'selected' : ''
          }`}
          onClick={() => onCareerChange(career)}
        >
          {career.name}
        </button>
      ))}
    </div>
  );
};

export default CareerSelector;
