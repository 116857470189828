import React, { useState } from "react";
import companiesData from './CompaniesData';

const CompanyCards = () => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState(companiesData);

  // Update filtered companies when selecting/deselecting a tag
  const toggleTag = (tag) => {
    const updatedTags = selectedTags.includes(tag)
      ? selectedTags.filter((t) => t !== tag)
      : [...selectedTags, tag];

    setSelectedTags(updatedTags);

    // Filter companies based on selected tags
    setFilteredCompanies(
      updatedTags.length === 0
        ? companiesData // Show all companies if no tags selected
        : companiesData.filter((company) => company.tags.some((t) => updatedTags.includes(t)))
    );
  };

  const allTags = Array.from(new Set(companiesData.flatMap((company) => company.tags))); // Unique tags

  return (
    <div className="company-cards-wrapper">
      {/* Tags selector */}
      <div className="tag-selector">
        {allTags.map((tag) => (
          <button
            key={tag}
            className={`tag-button ${selectedTags.includes(tag) ? "selected" : ""}`}
            onClick={() => toggleTag(tag)}
          >
            {tag}
          </button>
        ))}
      </div>

      {/* Cards container */}
      <div className="company-cards-container">
        {filteredCompanies.map((company) => (
          <div key={company.id} className="company-card">
            <div className="company-card-content">
              <div>
                {company.website ? (
                  <a href={company.website} target="_blank" rel="noopener noreferrer">
                    <img src={company.img} alt={company.title} />
                  </a>
                ) : (
                  <img src={company.img} alt={company.title} />
                )}
                {/*<h3>{company.title}</h3>*/}
                <p>{company.text}</p>
              </div>
              <div className="company-tags">
                {company.tags.map((tag) => (
                  <span key={tag} className="company-tag">{tag}</span>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompanyCards;