import React, { useState } from 'react';

const VideoModal = ({ link, img }) => {
  // State to control whether the modal is open or closed
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Functions to open and close the modal
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="img-column">
      {/* Image that opens the modal when clicked */}
      <img src={img} alt="carrera" onClick={openModal} className="clickable-image" />

      {/* Modal */}
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <button onClick={closeModal} className="close-button"> x </button>
            {/* Iframe for Google Drive video */}
            <iframe
              src={link}
              width="80%"
              height="80%"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
}

export default VideoModal;
