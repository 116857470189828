import React from 'react';

const About = () => {
  return (
    <section className="about" id="about">
      <div className="about-image">
        <img src="engineers.png" alt="Engineers" />
      </div>
      <div className="about-content">
        <p>FeimCodi és una iniciativa nascuda a Mallorca creada per una enginyera de software amb experiència en la indústria de l'animació 3D i la intel·ligència artificial. Aquesta iniciativa proposa explorar noves perspectives i oportunitats de desenvolupament per a les al·lotes mallorquines, creient fermament en el potencial de diversificar la nostra economia i apostar per sectors més sostenibles i ètics per a construir un futur més prometedor i equitatiu per a tots.</p>
        <p>FeimCodi aspira a ser una guia i una font d'inspiració per ajudar les dones a descobrir el món de la tecnologia i la programació, un camp ple de possibilitats i potencial de creixement, però que sovint desperta poc interès a causa dels estereotips i la falta d’informació. Es volen proporcionar les eines i el suport necessari perquè les al·lotes mallorquines puguin aconseguir les seves metes aprofitant els recursos disponibles en línia i mostrant una ruta clara i organitzada davant la gran quantitat d'informació disponible i a la vegada adaptar-la als gustos i interessos personals. Es vol fomentar la seva participació activa construint una comunitat inclusiva on totes puguin crèixer i dur a terme els seus projectes.</p>
      </div>
    </section>
  );
};

export default About;
