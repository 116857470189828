import React, { useState } from 'react';
import CareerSelector from './CareerSelector';
import CareerInfoCard from './CareerInfoCard';
import careersData from './CareersData';


const CareersSection = () => {
  const [selectedCareer, setSelectedCareer] = useState(careersData[0]);

  const handleCareerChange = (career) => {
    setSelectedCareer(career);
  };

  return (
    <div className="careers-section">
      <CareerSelector
        careers={careersData}
        selectedCareer={selectedCareer}
        onCareerChange={handleCareerChange}
      />
      <CareerInfoCard career={selectedCareer} />
    </div>
  );
};

export default CareersSection;
