import React, { useEffect, useRef } from 'react';

const hobbies = [
  { id: 1, title: 'Art i Fotografia', text: 'Crea eines d’edició d’imatges, per agilitzar el flux de treball o aplicar filtres. També inclou eines per dibuixar, com combinar colors, models anatòmics 3D ajustables o vectoritzar imatges.', img: 'hob1.png' },
  { id: 2, title: 'Idiomes', text: 'Treballa en projectes de traducció automàtica, assistents de veu, aplicacions d\'aprenentatge d\'idiomes o eines per practicar la pronunciació. ', img: 'hob2.png' },
  { id: 3, title: 'Cuina', text: 'Desenvolupa aplicacions per gestionar receptes, planificar menjades o crear llistes de la compra. Incorpora funcions com recomanacions segons els ingredients disponibles o gustos o inteleràncies dels usuaris.', img: 'hob3.png' },
  { id: 4, title: 'Cinema i Animació', text: 'Gestiona projectes cinematogràfics i d\'editació de vídeos. Incorpora eines per visualitzar vídeos i models 3D, així com funcions que automatitzin tasques repetitives dins de programes d\'animació i modelatge.', img: 'hob4.png' },
  { id: 5, title: 'Jardineria i Agricultura', text: 'Desenvolupa eines per gestionar cultius, detectar malalties de plantes mitjançant imatges i identificar tipus de plantes o fruits per classificar-los, o utilitza l\'anàlisi de dades per optimitzar rendiments.', img: 'hob5.png' },
  { id: 6, title: 'Nutrició', text: 'Analitza la ingesta nutricional, gestionar dietes equilibrades i oferir recomanacions personalitzades, amb seguiment de dades per millorar la salut i el benestar.', img: 'hob6.png' },
  { id: 7, title: 'Música', text: 'Gestiona biblioteques musicals, analitzar àudios o crear llistes de reproducció personalitzades, amb eines per editar pistes i millorar la qualitat sonora.', img: 'hob7.png' },
  { id: 8, title: 'Esports', text: 'Desenvolupa aplicacions per fer seguiment d\'entrenaments, crear comunitats d\'esportistes o analitzar vídeos dels moviments per identificar mals hàbits i millorar així el rendiment esportiu.', img: 'hob8.png' },
  { id: 9, title: 'Moda', text: 'Explora el món de la moda amb aplicacions que gestionen vestidors, creen looks personalitzats i analitzen estils. Inclou eines per dissenyar moda digital en 3D i seguir les tendències del moment.', img: 'hob9.png' },
  { id: 10, title: 'Disseny d\'Interiors', text: 'Planifica espais, seleccionar mobles i visualitzar decoracions en 3D. Inclou eines per analitzar l\'ús de l\'espai i fer recomanacions personalitzades segons les preferències dels usuaris.', img: 'hob10.png' },
  { id: 11, title: 'Salut', text: 'Utilitza l\'anàlisi de dades i la visualització d\'imatges per optimitzar la salut dels pacients i crea dispositius mèdics que millorin la qualitat de vida.', img: 'hob11.png' },
  { id: 12, title: 'Viatges', text: 'Millora l\'experiència de viatge amb planificació d\'itineraris personalitzats, sistemes de reserva eficients i mapes interactius de densitat turística.', img: 'hob12.png' },
];

const HobbyCards = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const handleWheel = (event) => {
      // If the displacement is vertical (deltaX = 0), use deltaY to move horizontally
      if (event.deltaX === 0 && event.deltaY !== 0) {
        event.preventDefault();
        container.scrollLeft += event.deltaY
      }
    };

    container.addEventListener('wheel', handleWheel);
    return () => container.removeEventListener('wheel', handleWheel);
  }, []);

  const scrollLeft = () => {
    containerRef.current.scrollLeft -= 300;
  };

  const scrollRight = () => {
    containerRef.current.scrollLeft += 300;
  };

  return (
    <div className="hobby-cards-wrapper">
      <div className="hobby-cards-container" ref={containerRef}>
        <div className="hobby-cards-row">
          {hobbies.map((hobby) => (
            <div key={hobby.id} className="hobby-card">
              <img src={hobby.img} alt={hobby.title} />
              <h3>{hobby.title}</h3>
              <p>{hobby.text}</p>
            </div>
          ))}
        </div>
      </div>
      <button className="scroll-button left" onClick={scrollLeft}>{"<"}</button>
      <button className="scroll-button right" onClick={scrollRight}>{">"}</button>
    </div>
  );
};

export default HobbyCards;
