const companiesData = [
  {
    id: 1,
    title: 'NBN23',
    tags: ["ciència de dades", "IA", "esports"],
    text: 'Utilitza big data i machine learning per monitoritzar i analitzar el rendiment esportiu en temps real en bàsquet i altres esports.',
    img: 'nbn23.png',
    website: 'https://nbn23.com/es/'
  },
  {
    id: 2,
    title: 'Seabin Project',
    tags: ["IA", "imatge", "ecologia"],
    text: 'Utilitza una combinació de visió per computador i sensors per detectar residus a les aigües de ports i marines. S\'han instal·lat Seabins a localitats com les Illes Balears.',
    img: 'seabin.png',
    website: 'https://seabin.io/home'
  },
  {
    id: 3,
    title: 'Cubiq Foods',
    tags: ["IA", "alimentació", "salut"],
    text: 'Empresa que treballa amb greixos saludables produïts mitjançant biotecnologia i intel·ligència artificial per l\'alimentació saludable.',
    img: 'cubiqfoods.png',
    website: 'https://www.cubiqfoods.com/'
  },
  {
    id: 4,
    title: 'Busuu',
    tags: ["llenguatge", "IA", "educació"],
    text: 'Ofereix aprenentatge d\'idiomes amb interaccions socials i IA per adaptar el ritme i contingut de les lliçons segons el progrés de l\'usuari.',
    img: 'busuu.png',
    website: 'https://www.busuu.com/es'
  },
  {
    id: 5,
    title: 'BMAT',
    tags: ["ciència de dades", "àudio"],
    text: 'Empresa dedicada al monitoratge de música utilitzant anàlisi de dades, amb l\'objectiu d\'ajudar els creadors a controlar els drets d\'autor.',
    img: 'bmat.png',
    website: 'https://www.bmat.com/ca/'
  },
  {
    id: 6,
    title: 'Seamm',
    tags: ["moda", "VR/AR"],
    text: 'Especialitzada en fashion tech digital, permet la creació de roba virtual per integrar en experiències digitals com videojocs o entorns de realitat augmentada.',
    img: 'seamm.png',
    website: 'https://seamm.io/'
  },
  {
    id: 7,
    title: 'QUIBIM',
    tags: ["salut", "IA", "imatge"],
    text: 'Especialitzada en anàlisi d\'imatges biomèdiques mitjançant intel·ligència artificial per a la diagnosi mèdica.',
    img: 'quibim.png',
    website: 'https://quibim.com/es/'
  },
  {
    id: 8,
    title: 'Sonosphere',
    tags: ["àudio", "IA", "cinema"],
    text: 'Empresa que treballa amb àudio immersiu 3D per experiències musicals i cinema amb tecnologies avançades de processament de so.',
    img: 'sonosphere.png',
    website: 'https://www.sonosphere.co.uk/',
  },
  {
    id: 9,
    title: 'Grey Parrot',
    tags: ["IA", "imatge", "ecologia"],
    text: 'Utilitza anàlisi d\'imatges basada en intel·ligència artificial per identificar i classificar diferents tipus de residus en cintes transportadores, ajudant a millorar els processos de reciclatge.',
    img: 'greyparrot.png',
    website: 'https://www.greyparrot.ai/',
  },
  {
    id: 10,
    title: 'Zelus',
    tags: ["IA", "ciència de dades", "esports"],
    text: 'Zelus Analytics utilitza intel·ligència artificial i anàlisi de dades avançada per proporcionar informació estratègica a equips esportius professionals, millorant el rendiment i la presa de decisions.',
    img: 'zelus.png',
    website: 'https://www.zelusanalytics.com/',
  },
  {
    id: 11,
    title: 'Retraced',
    tags: ["moda", "ecologia"],
    text: 'Utilitza tecnologia avançada per seguir i documentar cada pas de la producció, ajudant les marques a demostrar que treballen de manera ètica i sostenible.',
    img: 'retraced.png',
    website: 'https://www.retraced.com/',
  },
  {
    id: 12,
    title: 'River Cleaning',
    tags: ["ecologia"],
    text: 'River Cleaning desenvolupa sistemes flotants intel·ligents que recullen residus dels rius sense afectar la fauna ni la navegació, ajudant a mantenir els ecosistemes fluvials nets.',
    img: 'rivercleaning.png',
    website: 'https://rivercleaning.com/',
  },
  {
    id: 13,
    title: 'Sonarworks',
    tags: ["àudio"],
    text: 'Aquesta empresa es dedica a calibrar sistemes d\'àudio professionals per assegurar una experiència sonora consistent i precisa en diferents dispositius o sales.',
    img: 'sonarworks.png',
    website: 'https://www.sonarworks.com/',
  },
  {
    id: 14,
    title: 'Dolby',
    tags: ["àudio", "ciència de dades", "cinema"],
    text: 'Dolby utilitza tecnologia avançada d\'anàlisi de dades i algoritmes de processament de so per crear experiències d\'àudio immersives, per exemple optimitzar el so en pel·lícules.',
    img: 'dolby.png',
    website: 'https://www.dolby.com/es/',
  },
  {
    id: 15,
    title: 'Brightseed',
    tags: ["alimentació", "IA", "salut"],
    text: 'Utilitza algoritmes de machine learning per descobrir com certs components de les plantes poden millorar la salut humana, obrint nous camins en la nutrició i el desenvolupament de productes alimentaris més saludables.',
    img: 'brightseed.png',
    website: 'https://www.brightseedbio.com/',
  },
  {
    id: 16,
    title: 'Verbit',
    tags: ["llenguatge", "IA", "ciència de dades", "educació"],
    text: 'Verbit combina IA i eines d\'anàlisi de dades per crear transcripcions automàtiques i subtítols en temps real, orientat a institucions educatives i professionals de mitjans.',
    img: 'verbit.png',
    website: 'https://verbit.ai/es/',
  },
  {
    id: 17,
    title: 'Synthesia',
    tags: ["llenguatge", "IA", "imatge"],
    text: 'Empresa que utilitza intel·ligència artificial, síntesi de veu i generació de vídeo digital per crear presentadors virtuals que poden parlar en diversos idiomes.',
    img: 'synthesia.png',
    website: 'https://www.synthesia.io/',
  },
  {
    id: 18,
    title: 'SkinVision',
    tags: ["salut", "IA", "imatge"],
    text: 'Aquesta empresa utilitza la intel·ligència artificial per analitzar lesions cutànies per a la detecció precoç del càncer de pell, permetent als usuaris monitoritzar la salut de la seva pell de manera proactiva.',
    img: 'skinvision.png',
    website: 'https://www.skinvision.com/',
  },
  {
    id: 19,
    title: 'Owkin',
    tags: ["salut", "IA", "ciència de dades"],
    text: 'Està especialitzada en la intel·ligència artificial per a la investigació mèdica i el descobriment de medicaments, i analitza dades biomèdiques per accelerar el desenvolupament de tractaments i millorar l\'atenció als pacients.',
    img: 'owkin.png',
    website: 'https://www.owkin.com/',
  },
  {
    id: 20,
    title: 'Chaos',
    tags: ["imatge"],
    text: 'Desenvolupa eines de renderització i simulació per a artistes i dissenyadors, que combinen renderització fotorealista amb física avançada per produir visuals i efectes sorprenents.',
    img: 'chaos.png',
    website: 'https://www.chaos.com/es',
  },
  {
    id: 21,
    title: 'Extendra',
    tags: ["imatge", "IA", "VR/AR"],
    text: 'Optimitza processos creatius amb plataformes d\'IA que permeten disseny col·laboratiu, automatització de tasques repetitives i gestió eficient d\'assets en producció de contingut digital.',
    img: 'extendra.png',
    website: 'https://extendra.io/es',
  },
  {
    id: 22,
    title: 'flawless',
    tags: ["imatge", "IA", "cinema"],
    text: 'Pionera en tecnologia de transformació facial amb IA per a l\'edició de diàlegs i emocions en cinema, integrant deep learning per sincronitzar expressivitat i veu amb gran precisió.',
    img: 'flawless.png',
    website: 'https://www.flawlessai.com/',
  },
];


export default companiesData;