import React from 'react';
import Header from '../Header';
import CareersSection from './CareersSection';
import InfoBox from './InfoBox';
import infoBoxData from './InfoBoxData';
import HobbyCards from './HobbyCards'
import CompanyCards from './CompanyCards'
import './InfoPage.css';

function InfoPage() {
  return (
    <div className="info-page-container">
      <Header isMainHeader={false} />
      <div className="intro">
        <h1>Informa't</h1>
        <p>Si vols explorar el fascinant món de la tecnologia i la programació, has arribat al lloc adequat. T'interessa saber quines oportunitats ofereix aquest sector i com pots formar part d'ell? Aquesta web està plena d'informació essencial per ajudar-te a conèixer les enginyeries emergents.</p>
      </div>
      {infoBoxData.map((box) => (
        <InfoBox key={box.id} box={box}/>
      ))}
      <div className="careers-container">
        <h2>
          Carreres i sortides  <br />
          professionals
        </h2>
        <CareersSection />
      </div>
      <div className="hobby-container">
        <h2>
          Connecta amb els <br />
          teus Hobbies
        </h2>
        <HobbyCards />
      </div>
      <div className="company-container">
        <h2>
          Coneix empreses i <br />
          projectes reals
        </h2>
        <CompanyCards />
      </div>
    </div>
  );
}

export default InfoPage;
