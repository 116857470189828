import React from 'react';
import { Link } from 'react-router-dom';

const Header = ({ isMainHeader = false }) => {
  let navTextColor = isMainHeader ? "#FFF4F3" : "#0632AF";

  return (
    <header className="header">
      <Link to="/">
        <div className="logo">
          <img src={isMainHeader ? 'logo_pink.png' : 'logo_blue.png'} alt="feimcodi" />
        </div>
      </Link>
      <nav>
        <Link to="/" style={{ color: navTextColor }}> INICI </Link>
        <Link to="/info" style={{ color: navTextColor }}> INFORMA'T </Link>
      </nav>
    </header>
  );
};

export default Header;
