import React from 'react';
import { Link } from 'react-router-dom';

const FeatureCard = ({ icon, link, title, description, comingSoon }) => {
  return (
    <div className={`feature-card ${comingSoon ? 'disabled' : ''}`}>
      <div className="feature-icon-area">
        <div className="feature-icon-container">
          <div className="feature-icon">
            {link ? (
            <Link to={link}>
              <img src={icon} alt="test" />
            </Link>
            ) : (
              <img src={icon} alt="test" />
            )}
          </div>
        </div>
        {comingSoon && <span className="coming-soon">pròximament</span>}
      </div>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
};

export default FeatureCard;
