import React from 'react';
import VideoModal from './VideoModal'

const renderStars = (rating) => {
  const fullStars = Math.floor(rating); // Estrellas completas
  const hasHalfStar = rating % 1 !== 0; // Media estrella si es necesario
  const emptyStars = 5 - Math.ceil(rating); // Estrellas vacías restantes

  return (
    <div className="stars">
      {/* Estrellas completas */}
      {Array(fullStars).fill().map((_, index) => (
        <img key={`full-${index}`} src="star.png" alt="full star" />
      ))}

      {/* Media estrella */}
      {hasHalfStar && <img src="half-star.png" alt="half star" />}
    </div>
  );
};

const CareerInfoCard = ({ career }) => {
  return (
    <div className="career-info-card">
      <div className="career-main">
        <div className="img-column">
          <VideoModal link={career.link} img={career.img}/>
        </div>
        <div className="text-column">
          <h3>{career.name}</h3>
          <p>{career.description}</p>
          <p>{career.profile}</p>
        </div>
      </div>
      <div className="career-details">
        <div>
          <h4>Universitats</h4>
          <ul>
            {career.universities.map((university, index) => (
              <li key={index}>{university}</li>
            ))}
          </ul>
        </div>
        <div>
          <h4>Indústries</h4>
          <ul>
            {career.industries.map((industry, index) => (
              <li key={index}>{industry}</li>
            ))}
          </ul>
        </div>
        <div>
          <h4>Sortides</h4>
          <ul>
            {career.opportunities.map((opportunity, index) => (
              <li key={index}>{opportunity}</li>
            ))}
          </ul>
        </div>
        <div>
          <div className="career-rating">
            <p title="Mesura de la complexitat acadèmica de la carrera.">Dificultat<br />{renderStars(career.difficulty)}</p>
            <p title="Grau d’exigència en coneixements tècnics com matemàtiques, programació, etc.">Nivell Tècnic<br />{renderStars(career.technicalLevel)}</p>
            <p title="Presència d’elements de disseny, art visual, o expressió artística.">Creativitat<br />{renderStars(career.creativity)}</p>
            <p title="Capacitat per treballar en diferents indústries i aplicacions.">Versatilitat<br />{renderStars(career.versatility)}</p>
            <p title="Demanda del mercat laboral per a aquesta formació a nivell estatal.">Oportunitats<br />{renderStars(career.jobOpportunities)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerInfoCard;
