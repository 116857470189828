import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="social-media-content">
        <p>Segueix-nos a les xarxes per estar al dia de les novetats</p>
      </div>
      <div className="social-media-icon">
        <a href="https://www.linkedin.com/in/aina-nicolau-orell-a6800aa4/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} size="3x" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
