import React from 'react';

const InfoBox = ({ box }) => {

  const boxStyle = {
    flexDirection: box.reversed ? 'row-reverse' : 'row'
  };

  return (
    <div className="info-box" style={boxStyle}>
      <div className="info-box-img">
        <img src={box.img} alt={box.alt} />
      </div>
      <div className="info-box-text">
        <h3>{box.title}</h3>
        {box.text.map((p, index) => (
          <p key={index}>{p}</p>
        ))}
      </div>
    </div>
  );
};

export default InfoBox;
