import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import FeaturesSection from './components/FeaturesSection';
import About from './components/About';
import Footer from './components/Footer';
import InfoPage from './components/InfoPage/InfoPage';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <div className="landing-page">
                <div className="hero-container">
                  <Header isMainHeader={true} />
                  <HeroSection />
                </div>
                <FeaturesSection />
                <About />
                <Footer />
              </div>
            }
          />
          <Route path="/info" element={<InfoPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
